import {keyframes} from 'styled-components'

export const pulse = keyframes`
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`

export const upDown = keyframes`
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
`