import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

import ContentBlock from "../components/contentBlock";
import screenContent from "../images/screen-content.gif";
import { graphql } from "gatsby";
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image";
// import Column from "../components/layout/bodyColumn"
import AnimPageContainer from "../components/layout/animPageCont";
// Images
import computer from "../images/icons/computer.svg";
import screens from "../images/icons/screens.png";
import controls from "../images/icons/controls.svg";
import saveMoney from "../images/icons/save-money.svg";
import platforms from "../images/icons/platforms.svg";
import { upDown } from "../animations";
import { LinkBtn } from "../components/layout/ui";
import styled from "styled-components";

const blockData = [
  {
    image: computer,
    heading: <h3>Digital Charts Re-Imagined</h3>,
    text: (
      <p>
        Simple, elegant and intuitive. Eyechart.Online is a web application that
        turns any PC, Mac, laptop or tablet into a fully fledged digital chart &
        test suite. No setup or installation is required. Simply log in and
        start using. It just works.{" "}
      </p>
      // text: (
      //   <p>
      //     Simple, elegant and intuitive. Eyechart.Online is a digital chart and
      //     diagnostics web application that works on any device with an internet
      //     connection and a browser. No installation is required. Simply log in and
      //     start using. It just works.{" "}
      //   </p>
    ),
    link: "/what",
  },
  {
    image: screens,
    heading: <h3>Much more than just acuity charts</h3>,
    text: (
      <p>
        Eyechart.Online includes stereopsis tests, colour vision tests, contrast
        sensitivity tests, an interactive fan &amp; block, image slideshows,
        fixation disparity tests, letter randomisation, resizable targets,
        phoria tests, programmable sequences, adjustable anaglyph colors and
        much more. Save thousands by eliminating the need to purchase additional
        tests.{" "}
      </p>
    ),
    link: "/features",
  },
  {
    image: controls,
    heading: <h3>Built for speed. Easy to control</h3>,
    text: (
      <p>
        Controlling the app and switching charts is quick and intuitive. Easy to
        control with a small wireless keyboard, a regular mouse, an air mouse or
        our dedicated remote control.
      </p>
    ),
    link: "/how",
  },
  {
    image: saveMoney,
    heading: <h3>Incredible return on investment</h3>,
    text: (
      <p>
        For the price of about 2 cups of coffee per week you’ll have a state of
        the art Digital Chart and Diagnostic system, regularly updated, with
        full ongoing support. The return in terms of patient retention,
        modernisation, time savings and money savings could be worth 50 times
        that or more.
      </p>
    ),
    link: "/get-started",
  },
  {
    image: platforms,
    heading: <h3>Software only or complete solution</h3>,
    text:
      "You can use Eyechart.Online on any computer (Desktop / Laptop) with any OS (Mac, Windows or Linux). We also have a complete solution including the hardware that auto starts the application when switched on.",
    link: "/how",
  },
  {
    buttons: [
      <LinkBtn to="/what">What is Eyechart.Online</LinkBtn>,
      // <ExtLinkBtn
      //   href="https://eyechart.online"
      //   target="_blank"
      //   rel="noopener noreferrer"
      // >
      //   Start Application
      // </ExtLinkBtn>,
    ],
  },
];

const BigBanner = styled.div`
  position: relative;
  height: 95vh;
  /* padding-top: 60px; */
  padding-top: calc(0.1 * 100vh);
  @media (orientation: portrait) {
    padding-top: calc(0.3 * 100vh);
  }
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  background: radial-gradient(
    circle at center,
    rgba(0, 65, 97, 0.05) 50%,
    rgba(0, 65, 97, 0.4) 100%
  );
  border-radius: 0% 0% 50% 0% / 0% 0% 30% 0%;
  box-shadow: 2px 2px 8px -1px inset rgba(0, 0, 0, 0.75);
`;

const MonitorContainer = styled.div`
  position: relative;
  width: 700px;
  height: 615px;
`;

const ScrollHintBtn = styled.button.attrs({
  type: "button",
})`
  display: block;
  position: absolute;
  bottom: 5%;
  right: 5%;
  color: salmon;
  padding: 0px;
  animation: ${upDown} 1s ease-in-out infinite both;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 5em;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
  cursor: pointer;
`;

const HomePage = ({ data }) => {
  const scrollDownBtnHandler = () => {
    document
      .querySelector("#start")
      .scrollIntoView({ block: "start", behavior: "smooth" });
  };

  return (
    <>
      <SEO title="Home" />
      <AnimPageContainer>
        <BigBanner>
          <MonitorContainer>
            <GatsbyImage
              image={data.file.childImageSharp.gatsbyImageData}
              alt="Monitor with EyeChart.Online"
            />
            <img
              src={screenContent}
              alt="screen content"
              style={{
                position: "absolute",
                display: "block",
                left: 0,
                top: 0,
              }}
            />
          </MonitorContainer>
          <ScrollHintBtn onClick={scrollDownBtnHandler}>⬇</ScrollHintBtn>
        </BigBanner>
      </AnimPageContainer>
      <Layout>
        {blockData.map((obj, idx) => (
          <ContentBlock key={`cb-${idx}`} {...obj} />
        ))}
      </Layout>
    </>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "imac27-pf-screen.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
